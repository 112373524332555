<template>
    <div class="layout--main main-vertical navbar-floating footer-static">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    components: {
    },
    computed: {},
    methods: {},
  }
</script>
